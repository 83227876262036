<template>
	<product-landing-page :page-name="pageName" />
</template>
<script>
import ProductLandingPage from './components/ProductLandingPage1';

export default {
	name: 'AgencyBranLandingPage',
	components: {
		ProductLandingPage,
	},
	data() {
		return {
			pageName: this.$route.path.split('/')[2],
		};
	},
};
</script>
